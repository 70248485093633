import { date, string } from 'yup'

export const FeeByPartnersFilterSchema = {
  initialDate: date()
    .required('Data inicial é obrigatória')
    .test(
      'initialDateMustBeLessThanOrEqualFinalDate',
      'A data inicial deve ser menor ou igual à data final',
      (value, ctx) => {
        if (!value || !ctx?.parent?.finalDate) {
          return true
        }
        const finalDate = Date.parse(ctx.parent.finalDate)
        const initialDate = Date.parse(value)

        return initialDate <= finalDate
      }
    )
    .test(
      'dateRangeMustNotExceed31Days',
      'O intervalo entre as datas não deve exceder 31 dias',
      (value, ctx) => {
        if (!value || !ctx?.parent?.finalDate) {
          return true
        }
        const initialDate = Date.parse(value)
        const finalDate = Date.parse(ctx.parent.finalDate)

        const differenceInDays = (finalDate - initialDate) / (1000 * 60 * 60 * 24)

        return differenceInDays <= 31
      }
    ),

  finalDate: date()
    .required('Data final é obrigatória')
    .test(
      'finalDateMustBeAfterOrEqualInitialDate',
      'A data final deve ser maior ou igual à data inicial',
      (value, ctx) => {
        if (!value || !ctx?.parent?.initialDate) {
          return true
        }
        const initialDate = Date.parse(ctx.parent.initialDate)
        const finalDate = Date.parse(value)

        return finalDate >= initialDate
      }
    )
    .test(
      'dateRangeMustNotExceed31Days',
      'O intervalo entre as datas não deve exceder 31 dias',
      (value, ctx) => {
        if (!value || !ctx?.parent?.initialDate) {
          return true
        }
        const initialDate = Date.parse(ctx.parent.initialDate)
        const finalDate = Date.parse(value)

        const differenceInDays = (finalDate - initialDate) / (1000 * 60 * 60 * 24)

        return differenceInDays <= 31
      }
    ),

  reservationCode: string(),
  aut: string(),
  doc: string(),
  companyId: string()
}
