import { getImageUrl } from 'Core/Store/Company'
import { MetaParser } from './Meta'

export const CompanyParser = {
  list ({ data }) {
    const { companies, meta } = data

    const parsedCompanies = companies.map(company => ({
      id: company.id,
      name: company.name
    }))

    return {
      parsedCompanies,
      meta: MetaParser.meta(meta)
    }
  },
  listUserCompanies ({ companies = [] }) {
    return companies.map(company => ({
      id: company.id,
      name: company.name,
      image: getImageUrl(company.image)
    }))
  }
}
