import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from './useQuery'

export const useParamsFilter = (defaultFilters = {}) => {
  const history = useHistory()
  const [params] = useQuery()

  const pushQueryParams = useCallback((filters) => {
    const queryParams = Object.entries(filters).reduce((acc, [key, value]) => {
      if (!value) return acc

      if (Array.isArray(value)) {
        return acc.concat(
          value.reduce(
            (subAcc, item) => subAcc.concat(`${key}[]=${encodeURIComponent(item)}&`),
            ''
          )
        )
      }

      if (value instanceof Date) {
        return acc.concat(`${key}=${value.toISOString().slice(0, 10)}&`)
      }

      return acc.concat(`${key}=${encodeURIComponent(value)}&`)
    }, '')

    history.push({ search: `?${queryParams.slice(0, -1)}` })
  }, [history])

  const changeParams = (filters) => pushQueryParams(filters)

  useEffect(() => {
    if (Object.keys(defaultFilters)?.length === 0) return
    if (Object.keys(params)?.length) return

    changeParams(defaultFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters])

  return [params, changeParams]
}
