/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Button, Input, Select, InputFormGroup, Card, Row, Column, colors, DatePicker } from '@bonitour/components'
import { FeeByPartnersFilterSchema } from './FeeByPartnersFilter.schema'
import { useForm } from '@bonitour/app-functions'
import { useGetCompanies } from 'Shared/hooks/useGetCompanies'

const stylishCardSearch = css`
  border: none;
  background-color: ${colors.gray8};
  padding: 1.25rem;
  margin-bottom: 1.25rem;
`
const button = css`
  height: 2.5rem;
  width: 100%;
  margin-top: auto;
`

const width100 = css`
  width: 100%;
`

const BASE_FORM = {}

export const FeeByPartnersFilter = ({
  handleFiltersChange,
  filters = undefined,
  loadingFeeByPartners
}) => {
  const {
    companiesOptions,
    loading
  } = useGetCompanies()

  const {
    form,
    errors,
    onSubmit,
    utils: {
      onInputChange,
      onInputBlur
    }
  } = useForm(filters || BASE_FORM, FeeByPartnersFilterSchema)

  const { initialDate, finalDate, reservationCode, aut, companyId, doc } = form

  return (
    <Card css={stylishCardSearch}>
      <Row>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data inicial' errorMessage={errors.initialDate}>
            <DatePicker
              id='initialDate'
              css={width100}
              value={initialDate}
              placeholder='Data Inicial'
              onChange={onInputChange('initialDate')}
              onBlur={onInputBlur('initialDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Data final' errorMessage={errors.finalDate}>
            <DatePicker
              id='finalDate'
              css={width100}
              value={finalDate}
              placeholder='Data Final'
              onChange={onInputChange('finalDate')}
              onBlur={onInputBlur('finalDate')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Código da reserva'>
            <Input
              id='reservationCode'
              value={reservationCode}
              onChange={onInputChange('reservationCode')}
              onBlur={onInputBlur('reservationCode')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Aut'>
            <Input
              placeholder='Digite o Aut'
              id='aut'
              value={aut}
              onChange={onInputChange('aut')}
              onBlur={onInputBlur('aut')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Doc'>
            <Input
              placeholder='Digite o Doc'
              id='doc'
              value={doc}
              onChange={onInputChange('doc')}
              onBlur={onInputBlur('doc')}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <InputFormGroup label='Empresas'>
            <Select
              id='companyId'
              value={companyId}
              onChange={onInputChange('companyId')}
              disabled={loading}
              options={companiesOptions || []}
            />
          </InputFormGroup>
        </Column>
        <Column phone={12} desktop={3}>
          <Button
            css={button}
            onClick={onSubmit(handleFiltersChange)}
            disabled={loadingFeeByPartners}
          >
            Pesquisar
          </Button>
        </Column>
      </Row>
    </Card>
  )
}
