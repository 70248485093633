
import { CompanyParser } from 'Core/Parser/Company'
import { OrbCore, OrbService } from './Orb'
import { CompanyModel } from 'Core/Model/Company'

const { companiesDomain } = OrbCore

export const CompanyService = {
  list (filters) {
    const params = CompanyModel.list(filters)

    return OrbService
      .get('/v1/companies', { params })
      .then(CompanyParser.list)
  },
  listUserCompanies (active) {
    return companiesDomain.listUserCompanies(active).then(CompanyParser.listUserCompanies)
  }
}
