import { useCallback, useEffect, useMemo, useState } from 'react'
import { CompanyService } from 'Core/Service/Company'
import { useEnabledFeeByPartners } from 'Domains/Binamik/FeeByPartnersReport/hooks/useEnabledFeeByPartners'

export const useGetCompanies = () => {
  const [loading, setLoading] = useState()
  const [companies, setCompanies] = useState([])

  const { enabledFeeByPartners } = useEnabledFeeByPartners()

  const fetchAllCompanies = useCallback(async () => {
    setLoading(true)
    try {
      const firstPageRes = await CompanyService.list({
        perPage: 50,
        page: 1
      })

      const nextPagesRes = await Promise.all(
        Array(firstPageRes?.meta?.totalPages - 1).fill().map(
          (_, i) => CompanyService.list({
            perPage: 50,
            page: i + 2
          })
        )
      )

      const allCompanies = firstPageRes?.parsedCompanies.concat(
        nextPagesRes.map((item) => item.parsedCompanies).flat()
      )

      setCompanies(allCompanies)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!enabledFeeByPartners || companies?.length) return

    fetchAllCompanies()
  }, [companies, enabledFeeByPartners, fetchAllCompanies])

  const companiesOptions = useMemo(
    () => ([
      { value: '', label: 'Todos' },
      ...companies?.map(
        ({ id, name }) => ({ value: id, label: name })
      )
    ])
    , [companies]
  )

  return {
    companiesOptions,
    loading
  }
}
