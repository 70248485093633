import { bonipagService } from 'Core/externals/Bonipag'
import { BinamikModel } from 'Core/Model/Binamik'
import { BinamikParser } from 'Core/Parser/Binamik'

export const getFeeByPartners = async (
  params
) => {
  const parsedModel = BinamikModel.getFeeByPartners(params)

  const { response } = await bonipagService
    .get('v1/binamik/fee_by_partners', { params: parsedModel })
    .then((res) => ({ response: res || null }))
    .catch((err) => ({ response: err.response || null }))

  if (!response) {
    console.error('Error getFeeByPartners - No response received')
    return [null, true]
  }

  const { data, status } = response

  if (!String(status).startsWith('2')) {
    console.error('Error getFeeByPartners - Status:', status, 'Data:', data)
    return [null, true]
  }

  return [BinamikParser.getFeeByPartners(data), false]
}
