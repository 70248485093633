export const CompanyModel = {
  list: (params) => {
    const {
      page,
      perPage
    } = params

    return {
      page,
      per_page: perPage
    }
  }
}
