import { DetailsTransactionParser } from './DetailsTransaction'
import { MetaParser } from './Meta'

export const TransactionParser = {
  single: (transaction) => {
    const {
      amount,
      aut,
      binamik_fee: binamikFee,
      brand_card: brandCard,
      braspag_meta: braspagMeta,
      card_fee: cardFee,
      card_final_digits: cardFinalDigits,
      client_info: clientInfo,
      created_at: createdAt,
      doc,
      external_payment_id: externalPaymentId,
      fee,
      gateway,
      history,
      installments,
      mdr_fee: mdrFee,
      multiponto: isMultiponto,
      net_value: netValue,
      payment_method: paymentMethod,
      reservation_code: reservationCode,
      return_code: returnCode,
      return_message_fraud: returnMessageFraud,
      vendor_name: vendorName,
      status
    } = transaction?.pay_payment ?? transaction ?? {}

    return {
      amount,
      aut,
      binamikFee,
      brandCard,
      cardFee,
      cardFinalDigits,
      createdAt,
      externalPaymentId,
      detailsTransaction: DetailsTransactionParser.translationReturnDetailsTransaction(
        {
          braspagMeta, externalPaymentId,
          clientInfo, brandCard,
          returnMessageFraud, returnCode
        }
      ),
      doc,
      fee,
      gateway,
      history: (history || []).map(TransactionParser.history),
      installments,
      isMultiponto,
      mdrFee,
      netValue,
      paymentMethod,
      reservationCode,
      returnCode,
      status,
      vendorName
    }
  },
  list: ({ meta: metadata, pay_payments = [] }) => {
    return {
      transactions: pay_payments.map(TransactionParser.single),
      meta: MetaParser.meta(metadata)
    }
  },
  history: (history) => {
    const {
      command,
      created_at: createdAt
    } = history?.history ?? history ?? {}

    return {
      command,
      createdAt
    }
  },
  message: ({ message, message_to_client: messageToClient, type }) => {
    return {
      message,
      messageToClient,
      type
    }
  }
}
