import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@bonitour/components'
import { useParamsFilter } from 'Shared/hooks/useParamsFilter'
import { getFeeByPartners } from 'Core/io/v1/binamik/getFeeByPartners'

export const useGetFeeByPartners = () => {
  const { add: addToast } = useToast()
  const [filters, setFilters] = useParamsFilter()
  const [loading, setLoading] = useState(false)
  const [feeByPartnersData, setFeeByPartnersData] = useState({
    results: []
  })

  const fetchFeeByPartners = useCallback(async (
    {
      newFilters
    }
  ) => {
    setLoading(true)
    const [results, hasError] = await getFeeByPartners(
      newFilters
    )

    if (hasError) {
      addToast('Erro ao buscar taxas dos parceiros')
      setLoading(false)
      return
    }

    setFeeByPartnersData(results)
    setLoading(false)
  }, [addToast])

  const handleFiltersChange = useCallback((newFilters) => {
    setFilters(newFilters)
    fetchFeeByPartners({
      newFilters
    })
  }, [fetchFeeByPartners, setFilters])

  useEffect(() => {
    if ((!filters?.initialDate && !filters?.finalDate) || feeByPartnersData.results.length) return

    fetchFeeByPartners({ newFilters: filters })
  }, [filters, fetchFeeByPartners, feeByPartnersData.results.length])

  return {
    filters,
    handleFiltersChange,
    feeByPartnersData,
    loading
  }
}
