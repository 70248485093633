/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Card, GhostButton, H1, PaginationBar, Row } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { TransactionFilter } from './Filter/TransactionFilter'
import { useListTransaction } from './Hooks/useListTransaction'
import { TransactionList } from './List/TransactionList'
import { TransactionModal } from './TransactionModal/TransactionModal'

const containerButton = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const noMargin = css`
  margin: 0;
`

export const Transaction = () => {
  const titleColumnTransactionListingTable = [
    'Compra em',
    'Empresa',
    'Aut',
    'Doc',
    'Reserva',
    'Pagamento',
    'Valor',
    'Multiponto',
    'Estado',
    'Ações'
  ]
  const titleColumnModalTable = ['ID interno', 'Cliente', 'Mensagem', 'Análise do antifraude']

  const { push } = useHistory()
  const [isVisible, setVisibility] = useState(false)
  const [modalData, setModalData] = useState()

  const toggleModal = () => setVisibility(!isVisible)

  const goBack = () => push('/dashboard')

  const {
    transactions,
    setFilters,
    pagination,
    handleOnPageChange,
    handleOnPerPageChange,
    loading,
    meta
  } = useListTransaction()

  const onFilterSubmit = filters => setFilters(filters)

  return (
    <>
      <TransactionModal
        isVisible={isVisible}
        toggleModal={toggleModal}
        modalData={modalData}
        titleColumnModalTable={titleColumnModalTable}
      />
      <Row css={containerButton}>
        <H1 css={noMargin}>Transações</H1>
        <GhostButton onClick={goBack}>Voltar</GhostButton>
      </Row>
      <Card>
        <TransactionFilter onFilterSubmit={onFilterSubmit} />
        <TransactionList
          titleColumnList={titleColumnTransactionListingTable}
          transactions={transactions}
          loading={loading}
          toggleModal={toggleModal}
          setModalData={setModalData}
          meta={meta}
        />
        <PaginationBar
          currentPage={pagination.page}
          perPage={pagination.perPage}
          totalPages={pagination.totalPages}
          totalElements={pagination.totalItems}
          onPageChange={handleOnPageChange}
          onPerPageChange={handleOnPerPageChange}
        />
      </Card>
    </>

  )
}
