/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { useCallback, useRef } from 'react'
import { useClickOutside } from '@bonitour/app-functions'
import { Label, flexHorizontallyCentered, AngleDownIcon, Manager, Reference, Popover, Row, Column, CogIcon, ReportsIcon, DocumentIcon, BankCardIcon, PlusIcon, BillIcon } from '@bonitour/components'
import { CardButton } from './CardButton'
import { resetLink, marginBottom, cardIcon, labelCardButton, angleDownStyle, linkColor, cardItems, cardItemIcon, plusIconBackground } from './TitleMenus.style'
import { APP_ENVIRONMENT } from 'Shared/config/env'
import { useListDigitalAccount } from 'Domains/DigitalAccount/Hooks/useListDigitalAccount'
import { FeatureEnabler } from 'Shared/contexts/Feature'
import { useUser } from 'Shared/contexts/User'
import { useEnabledFeeByPartners } from 'Domains/Binamik/FeeByPartnersReport/hooks/useEnabledFeeByPartners'

const { REACT_APP_NEWPAY_URL } = process.env

const marginBottom30 = marginBottom(30)

export const CardItemRow = ({ to = '', children }) => (
  <NavLink to={to} css={resetLink}>
    <Row css={linkColor}>
      <div css={flexHorizontallyCentered}>{children}</div>
    </Row>
  </NavLink>
)

export const CardItem = ({ title, icon: Icon = null, children, ...other }) => {
  const reference = useRef()
  const [isVisible, setVisibility] = useClickOutside(reference)
  const toggleVisibility = () => setVisibility(visibility => !visibility)

  return (
    <div ref={reference} {...other}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={toggleVisibility}>
              <CardButton>
                <div css={[flexHorizontallyCentered, cardItemIcon]}>
                  {Icon && <Icon css={cardIcon} />}
                  <AngleDownIcon css={angleDownStyle} />
                </div>
                <Label css={labelCardButton}>{title}</Label>
              </CardButton>
            </div>
          )}
        </Reference>
        {isVisible && (
          <Popover position='bottom' css={cardItems}>
            {children}
          </Popover>
        )}
      </Manager>
    </div>
  )
}

export const TitleMenus = ({ companyId }) => {
  const { digitalAccounts } = useListDigitalAccount(companyId, true)
  const haveDigitalAccount = !!digitalAccounts
  const { user } = useUser()

  const enableNewPay = useCallback(
    (children) => (enabled, variables) => {
      const companyWhitelist = variables?.allowedCompanies || variables?.allowed?.companies
      const userWhitelist = variables?.allowed?.users?.[companyId]

      const enabledForUserAndCompany =
        enabled &&
        (!companyWhitelist || companyWhitelist.includes(companyId)) &&
        (!userWhitelist || userWhitelist.includes(user?.id))

      return enabledForUserAndCompany ? children : null
    },
    [companyId, user?.id]
  )

  const { enabledFeeByPartners } = useEnabledFeeByPartners()

  return (
    <Row>
      <Column phone={12} css={marginBottom30}>
        {APP_ENVIRONMENT !== 'production'
          ? (
            <>
              <NavLink to={haveDigitalAccount ? '/digital-account' : '/digital-account/create'} css={resetLink}>
                <CardButton>
                  {!haveDigitalAccount ? <PlusIcon css={[cardIcon, plusIconBackground]} /> : <BankCardIcon css={cardIcon} />}
                  <Label css={labelCardButton}>Conta Digital</Label>
                </CardButton>
              </NavLink>
            </>
          )
          : null}

        <CardItem title='Relatórios' icon={ReportsIcon}>
          <CardItemRow to='/transaction'>Transações</CardItemRow>
          <CardItemRow to='/refund'>Estornos</CardItemRow>
          {enabledFeeByPartners &&
            <CardItemRow to='/fee-by-partners'>Taxas por parceiros</CardItemRow>
          }
        </CardItem>

        <NavLink to='/configuration' css={resetLink}>
          <CardButton>
            <DocumentIcon css={cardIcon} />
            <Label css={labelCardButton}>Cadastro</Label>
          </CardButton>
        </NavLink>

        <NavLink to='/company-config' css={resetLink}>
          <CardButton>
            <CogIcon css={cardIcon} />
            <Label css={labelCardButton}>Configurações</Label>
          </CardButton>
        </NavLink>
        <FeatureEnabler feature='new-pay'>
          {enableNewPay(
            <a href={REACT_APP_NEWPAY_URL} css={resetLink}>
              <CardButton>
                <BillIcon css={cardIcon} />
                <Label css={labelCardButton}>Pagar.me</Label>
              </CardButton>
            </a>
          )}
        </FeatureEnabler>
      </Column>
    </Row>
  )
}
