/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect } from 'react'
import { jsx, css } from '@emotion/core'
import { Card, colors, GhostButton, H1, Row, TableRow, TableV2, TooltipOnHover } from '@bonitour/components'
import { useHistory } from 'react-router-dom'
import { FeeByPartnersFilter } from './FIlter/FeeByPartnersFilter'
import { useGetFeeByPartners } from './hooks/useGetFeeByPartners'
import { formatMoney, formatDate } from '@bonitour/common-functions'
import { useEnabledFeeByPartners } from './hooks/useEnabledFeeByPartners'

const containerButton = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    margin: 0;
  }
`

const containerTotalizers = css`
  gap: 0.625rem;
`

const tableV2Container = css`
  .TableRow {
    .TableCell {
      overflow: initial;
    }

    color: ${colors.gray3};;

    .safe_overflow__text {
      width: 100%;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .safe_overflow__container {
      max-width: 10rem;

      .tooltip-on-hover {
        &::before {
          content: '';
          position: absolute;
          inset: -15px;
          z-index: -1;
        }
      }
    }
  }
`

const COLUMNS_WIDTHS = ['6.5rem', '12rem', '8rem', '8rem', '4rem', '7.5rem', '7rem', '7rem', '7rem']
const TITLES = [
  'Data', 'Parceiro', 'Aut/Doc', 'Cod reserva', 'Parcela', 'Taxa', 'Tx Completa', 'Total de tx', 'Total braspag'
]

export const FeeByPartnersReport = () => {
  const { push } = useHistory()

  const goBack = useCallback(() => push('/dashboard'), [push])

  const { enabledFeeByPartners } = useEnabledFeeByPartners()

  useEffect(() => {
    if (!enabledFeeByPartners) {
      push('/')
    }
  }, [enabledFeeByPartners, push])

  const {
    filters,
    handleFiltersChange,
    feeByPartnersData,
    loading
  } = useGetFeeByPartners()

  return (
    <>
      <Row css={containerButton}>
        <H1 className='title'>Taxas por parceiros</H1>
        <GhostButton onClick={goBack}>Voltar</GhostButton>
      </Row>

      <Row css={containerTotalizers}>
        <Card>
          <p>Total de taxas</p>
          {formatMoney(feeByPartnersData?.totalValue)}
        </Card>

        <Card>
          <p>Total de taxas completas</p>
          {formatMoney(feeByPartnersData?.totalValueWithTax)}
        </Card>
      </Row>

      <Card>
        <FeeByPartnersFilter
          handleFiltersChange={handleFiltersChange}
          filters={filters}
          loadingFeeByPartners={loading}
        />
        <TableV2
          isLoading={loading}
          labels={TITLES}
          columnsWidths={COLUMNS_WIDTHS}
          mobileBreakpoint={1024}
          customCss={[tableV2Container]}
        >
          {
            feeByPartnersData.results.map((item) => (
              <TableRow
                key={item?.id}
              >
                <>{formatDate(item?.forecastedDate || '')}</>
                <TooltipOnHover key='partnerName' className='safe_overflow__container' text={item?.partnerName}>
                  <span className='safe_overflow__text'>{item?.partnerName}</span>
                </TooltipOnHover>
                <>{item?.aut}/{item?.doc}</>
                <>{item?.reservationCode}</>
                <>{item?.installmentNumber}/{item?.installments}</>
                <>{item?.binamikFee}</>
                <>{item?.binamikFeeWithTax}</>
                <>{item?.totalFee}</>
                <TooltipOnHover key='installmentNetAmount' className='safe_overflow__container' text={item?.gatewayId}>
                  {item?.installmentNetAmount}
                </TooltipOnHover>
              </TableRow>
            ))
          }
        </TableV2>
      </Card>
    </>

  )
}
