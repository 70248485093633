import { useHistory, useLocation } from 'react-router-dom'
import { getQueryParams } from '@bonitour/common-functions'
import { useCallback, useMemo } from 'react'

const stringifyQueryParams = (params) => {
  return new URLSearchParams(params).toString()
}

export const useQuery = () => {
  const { push } = useHistory()
  const { search = '' } = useLocation()

  const queryParams = useMemo(() => getQueryParams(search), [search])

  const changeQueryParam = useCallback((key, value) => {
    const newParams = { ...queryParams, [key]: encodeURIComponent(String(value)) }
    push({ search: stringifyQueryParams(newParams) })
  }, [push, queryParams])

  const removeQueryParam = useCallback((key) => {
    const newParams = { ...queryParams }
    delete newParams[key]
    push({ search: stringifyQueryParams(newParams) })
    return `?${stringifyQueryParams(newParams)}`
  }, [push, queryParams])

  return [queryParams, { changeQueryParam, removeQueryParam }, search]
}
