import { Configuration } from 'Domains/Configuration/Configuration'
import { CompanyConfig } from 'Domains/CompanyConfig/CompanyConfig'
import { Dashboard } from 'Domains/Dashboard/Dashboard'
import { DigitalAccount } from 'Domains/DigitalAccount/DigitalAccount'
import { Layout } from 'Domains/Layout/Layout'
import { Refund } from 'Domains/Refund/Refund'
import { Transaction } from 'Domains/Transaction/Transaction'
import { TransactionEntry } from 'Domains/Transaction/TransactionEntry/TransactionEntry'
import { DigitalAccountStatus } from 'Domains/DigitalAccount/Create/DigitalAccountStatus/DigitalAccountStatus'
import { NewCreateDigitalAccount } from 'Domains/DigitalAccount/Create/NewCreateDigitalAccount'
import { APP_ENVIRONMENT } from 'Shared/config/env'
import { FeeByPartnersReport } from 'Domains/Binamik/FeeByPartnersReport/FeeByPartnersReport'
// import { CreateDigitalAccount } from 'Domains/DigitalAccount/Create/CreateDigitalAccount'

export const routes = [
  {
    path: '/',
    component: Layout,
    routes: [
      {
        path: 'company-config',
        component: CompanyConfig
      },
      {
        path: 'refund',
        component: Refund
      },
      {
        path: 'configuration',
        component: Configuration
      },
      {
        path: 'transaction/entry/:id',
        component: TransactionEntry
      },
      {
        path: 'transaction',
        component: Transaction
      },
      {
        path: 'fee-by-partners',
        component: FeeByPartnersReport
      },
      ...(APP_ENVIRONMENT !== 'production'
        ? [
          {
            path: 'digital-account/create/status',
            component: DigitalAccountStatus
          },
          {
            path: 'digital-account/create',
            component: NewCreateDigitalAccount
          },
          {
            path: 'digital-account',
            component: DigitalAccount
          }
        ]
        : []),
      {
        path: 'dashboard',
        component: Dashboard
      },
      {
        path: 'dashboard',
        redirect: true
      }
    ]
  }
]
