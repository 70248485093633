export const BinamikModel = {
  getFeeByPartners: (params) => {
    const {
      initialDate,
      finalDate,
      reservationCode,
      doc,
      aut,
      companyId
    } = params

    const safeInitialDate = initialDate instanceof Date ? initialDate.toISOString().split('T')[0] : initialDate
    const safeFinalDate = finalDate instanceof Date ? finalDate.toISOString().split('T')[0] : finalDate

    return {
      initial_date: safeInitialDate,
      final_date: safeFinalDate,
      partners_ids: !companyId ? undefined : [companyId],
      reservation_code: reservationCode || undefined,
      aut: aut || undefined,
      doc: doc || undefined
    }
  }
}
