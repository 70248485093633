import { useMemo } from 'react'
import { useCompany } from 'Shared/contexts/Company'
import { useFeatureFlag } from 'Shared/contexts/Feature'

export const useEnabledFeeByPartners = () => {
  const [enabled, variables] = useFeatureFlag('fee-by-partners')

  const { company } = useCompany()

  const enabledFeeByPartners = useMemo(
    () => {
      const companyWhitelist = variables?.allowed?.companies
      return enabled && companyWhitelist.includes(company?.id)
    }
    , [company?.id, enabled, variables?.allowed?.companies]
  )

  return {
    enabledFeeByPartners
  }
}
